import { isServer, debug, sendToSentry } from "scripts/common"

let loaded = false

const PIXEL_ID = {
  ES: "541107486286779",
  PT: "1487308651416999",
  IT: "416923092516633"
}

function verifyInit() {
  if (!loaded) {
    console.warn("Facebook pixel not initialized before using call init with required params")
  }

  return !isServer && loaded
}

function log(...args) {
  console.debug(...["[facebook-pixel]"].concat(args))
}

export function init(countryCode = "ES") {
  const pixelId = PIXEL_ID[countryCode]

  setTimeout(() => {
    loaded = typeof window !== "undefined" && !!window.fbq
    /* eslint-disable */
    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      }
      if (!f._fbq) f._fbq = n
      n.push = n
      n.loaded = !0
      n.version = "2.0"
      n.queue = []
      t = b.createElement(e)
      t.async = !0
      t.src = v
      s = b.getElementsByTagName(e)[0]
      s.parentNode.insertBefore(t, s)
    })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js")
    /* eslint-enable */

    if (!pixelId) {
      console.warn("Please insert pixel id for initializing")
    } else {
      window.fbq("init", pixelId, {})

      loaded = true
    }
    sendPageView()
  }, 3000)
}

export function sendPageView() {
  if (!verifyInit()) return

  if (debug) {
    log("called fbq('track', 'PageView');")
    return
  }

  try {
    window.fbq("track", "PageView")
  } catch (error) {
    sendToSentry("FbPixel", error)
  }
}

export function track(title, data) {
  if (!verifyInit()) return

  if (debug) {
    log(`called fbq('track', '${title}');`)
    if (data) log("with data", data)

    return
  }

  try {
    window.fbq("track", title, data)
  } catch (error) {
    sendToSentry("FbPixel", error)
  }
}

export function sendLead() {
  track("Lead", {})
}

export function sendCarView(ids, price) {
  track("ViewContent", {
    content_type: "vehicle",
    content_ids: ids,
    value: price,
    currency: "EUR"
  })
}
