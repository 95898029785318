import { NextSeo, LocalBusinessJsonLd } from "next-seo"
import { useRouter } from "next/router"

import { PATH_LIST, getPath } from "../../helpers/getPath"

export default function GlobalSeoData() {
  const generateLanguages = () => {
    const router = useRouter()
    const pathname = router.asPath
    const pathWithoutLocale = pathname.replace(/\/es|\/it|\/pt/i, "")
    const match = pathname.match(/\/(es|it|pt)\b/)
    const locale = match && match[1]

    try {
      let page = ""
      const keys = Object.keys(PATH_LIST[locale]);
      
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const path = getPath(locale, key, router.query)
        
        if (path === pathWithoutLocale) {
          page = key
          break
        }
      }

      const pathES = getPath("es", page, router.query)
      const pathPT = getPath("pt", page, router.query)
      const pathIT = getPath("it", page, router.query)

      return [
        {
          hrefLang: "x-default",
          href: `https://swipcar.com/es/${pathES}`
        },
        {
          hrefLang: "es",
          href: `https://swipcar.com/es${pathES}`
        },
        {
          hrefLang: "pt",
          href: `https://swipcar.com/pt${pathPT}`
        },
        {
          hrefLang: "it",
          href: `https://swipcar.com/it${pathIT}`
        }
      ]
    } catch (error) {
      return []
    }
  }

  return (
    <>
      <NextSeo languageAlternates={generateLanguages()} />

      <LocalBusinessJsonLd
        type="AutoRental"
        id="https://swipcar.com/es"
        name="Swipcar"
        description=""
        url="https://swipcar.com/es"
        telephone="+34 910 782 787"
        images={["https://swipcar.com/wp-content/uploads/2020/08/118293707_2750700981841886_4195929263470997185_o.jpg"]}
        address={{
          streetAddress: "Calle Valle del Roncal 12. Planta 1. Oficina 13",
          addressLocality: "Las Rozas",
          addressRegion: "Madrid",
          postalCode: "28232",
          addressCountry: "ES"
        }}
        geo={{
          latitude: "40.5168787",
          longitude: "-3.8829244"
        }}
        priceRange="€€"
        openingHours={[
          {
            opens: "09:00",
            closes: "18:30",
            dayOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"]
          }
        ]}
        sameAs={[
          "https://www.facebook.com/Swipcar-1941213906123935",
          "https://www.instagram.com/swipcar",
          "https://www.youtube.com/channel/UCKdvqfjYM53rrNh9RWKtq_Q",
          "https://twitter.com/swipcar_renting",
          "https://www.linkedin.com/company/11181106"
        ]}
      />
    </>
  )
}
