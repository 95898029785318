import { Router } from "next/router"
import { parse } from "query-string"
import { init as initGTM } from "scripts/GoogleTagManager"
import { init as initSentry } from "scripts/Sentry"
import { init as initFB, sendPageView as sendFBPageView } from "scripts/FacebookPixel"
import { sendPageView as sendGAPageView } from "scripts/GoogleAnalytics"
import { init as initBing, sendPageView as sendBingPageView } from "scripts/BingAds"
import { MARKETING_FIELDS } from "helpers/constants"
import { createCookie } from "helpers/cookies"
import { init as initClientFingerprint } from "lib/fingerprint"

const isServer = typeof window === "undefined"
let lastPath = !isServer && window.location.pathname
if (!isServer) window.firstPath = true

export default function initializers() {
  initSentry()

  if (isServer) return
  const { pathname } = window.location
  const countryCode = window.countryCode || pathname.split("/")[1].toUpperCase()

  initGTM(countryCode, pathname)
  initFB(countryCode)
  initBing()
  initClientFingerprint()

  Router.events.on("routeChangeComplete", url => {
    window.firstPath = false
    const path = url.split("?")[0]
    if (lastPath === path) return
    lastPath = path
    sendGAPageView(path)
    sendBingPageView(path)
    sendFBPageView()
  })

  const { keyword, ...rest } = parse(window.location.search)

  MARKETING_FIELDS.forEach(field => {
    createCookie(field, rest[field])
  })
}
